<template>
  <div style="width: 100%;display: flex;justify-content: center">
    <a class="btn btn-google" href="https://play.google.com/store/apps/details?id=com.easysaff.liberrex" target="_blank" title="Google Play">Google Play</a>
  </div>
</template>
<style scoped>
@font-face{font-family:"fontfutura";src:url("https://fonts.googleapis.com/css?family=Open+Sans") format("ttf");font-weight:normal;font-style:normal;}
a.btn-google{color:#fff}
.btn{padding:10px 16px;margin:5px;font-size:18px;line-height:1.3333333;border-radius:6px;text-align:center;white-space:nowrap;vertical-align:middle;-ms-touch-action:manipulation;touch-action:manipulation;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;border:1px solid transparent;font-weight:500;text-decoration:none;display:inline-block}
.btn:active:focus,.btn:focus{outline:0}
.btn:focus,.btn:hover{color:#333;text-decoration:none;outline:0}
.btn:active{outline:0;-webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,.125);box-shadow:inset 0 3px 5px rgba(0,0,0,.125)}
.btn-google{color:#fff;background-color:#111;border-color:#000;padding:15px 16px 5px 40px;position:relative;font-family:fontfutura;font-weight:600}
.btn-google:focus{color:#fff;background-color:#555;border-color:#000}
.btn-google:active,.btn-google:hover{color:#fff;background-color:#555;border-color:#000;}
.btn-google:before{content:"";background-image:url(../assets/images/playstore.svg);background-size:cover;background-repeat:no-repeat;width:30px;height:30px;position:absolute;left:6px;top:50%;margin-top:-15px}
.btn-google:after{content:"Télécharger EASYSAFF";position:absolute;top:5px;left:40px;font-size:10px;font-weight:400;}
/*]]>*/
</style>
<script>
export default {
  name : "PlayStoreButton",
}
</script>