<template>
    <div>
  <div class="auth-title-container">
    <h1 class="auth-title">
        La solution Easy Saff vous propose une application mobile pour vos clients
    </h1><br>
  </div>
  <div class="auth-header-container">
      <div class="circle auth-green-circle"></div>
      <div class="circle auth-yellow-circle"></div>
      <div class="circle auth-red-circle"></div>
      <div style="display: flex;width: 90%">

      <div class="title-container">
          <div>
          <h1 class="easySaff-auth">Installez Easy Saff en quelques clics.</h1><br>
            <a target="_blank" href="https://app.liberrex.com/login/tt">
              <div class="auth-button">
                <img height="50" src="../assets/images/client-check.png"/>
                <div class="auth" style="display: flex;width: 75%;justify-content: center;color: white !important;">
                  <div style="margin-left: auto; margin-right: auto">S'authentifier</div>
                </div>
              </div></a>
            <a @click="showForm = true;">
              <div class="auth-button">
                <img height="50" src="../assets/images/assistant.png"/>
                <div class="auth long-text" style="display: flex;width: 84%;justify-content: center;color: white !important;">
                  <div style="margin-left: auto; margin-right: auto">Contacter votre chargé clientéle</div>
                </div>
              </div></a>

            <PlayStoreButton></PlayStoreButton>
          </div>
      </div>
      </div>
      <img class="two-phones-image" height="660" src="../assets/images/two-phones.png">
  </div>
    </div>
  <form-popup @close="showForm = false" v-model="showForm" v-if="showForm">
  </form-popup>
</template>
<script>

    import FormPopup from "@/components/FormPopup.vue";
    import PlayStoreButton from "@/components/PlayStoreButton.vue";

    export default {
        name: 'AuthenticationSection',
      components: {PlayStoreButton, FormPopup},
        data() {
          return {
              showForm: false,
          }
      }
    }
</script>

<style scoped>

.auth-title{
    text-align: center;
    width: 80%;
}
.auth-title-container {
    margin-bottom: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #0E2976;
    font-weight: 700;
}
.title-container {
    width: 100%;padding-top: 100px;justify-content: center;display: flex;
}
.auth-button{
    display: flex;
    padding-left:8%;
    padding-top: 5px;
    cursor: pointer;
    color: white;
    margin: 20px;
    width: 90%;
    height: 75px;
    border-radius: 30px;
    border: 2px solid white;
    font-weight: 700;
}
.auth-button:hover{
    opacity: 0.8;
}
.auth{
    font-size: 20px !important;
    font-weight: 900 !important;
    padding-top: 12px !important;

}
.circle {
    width: 40px;
    height: 40px;
    border-radius: 300px;
    position: absolute;
    filter: drop-shadow(2px 2px 0.15rem black)
}
.auth-green-circle{
    margin-left: 3%;
    margin-top: 26%;
    background: linear-gradient(111.3deg, #279954 9.6%, #2DB36F 93.6%);
}

.auth-yellow-circle{
    margin-left: 8%;
    margin-top: 31%;
    background: linear-gradient(111.3deg, #96812E 9.6%, #E0D752 93.6%);
}


.auth-red-circle{
    margin-left: 15%;
    margin-top: 35%;
    background: linear-gradient(111.3deg, #E43824 9.6%, #F57825 93.6%);
}
.two-phones-image{
    margin-top: -100px;
    float:right;
    margin-right: 100px;
    filter: drop-shadow(5px 5px 0.75rem black)
}
  .auth-header-container {
      display: flex;
      width: 100%;
      background: linear-gradient(111.3deg, #28A7CA 9.6%, #28A9C9 93.6%);
  }
  .easySaff-auth {
      text-align: center;
  }
  .auth-header-container h1 {
      width: 100%;
      font-weight: 800;
      color: white;
      font-size: 30px;
  }
  .header-text{
      color: white;
      width: 80%;
  }
  @media (max-width: 1320px) {
      .auth-header-container{
          justify-content: center;
      }
      .two-phones-image{
          display: none;
      }
      .circle{
          display: none;
      }
      .auth-header-container h1 {
        font-size: 40px;
      }
      .easySaff-auth {
          font-size: 57px !important;
      }
  }
  @media (max-width: 1050px) {

      .auth-header-container h1 {
          font-size: 30px;
      }
      .easySaff-auth {
          font-size: 42px !important;
      }
  }
  @media (max-width: 780px) {
      .auth-button{
          font-size: 13px;
      }
    .long-text{
      font-size: 15px !important;
      text-align: center;
      margin-left: -10px;
    }
      .title-container {
          padding-top: 20px
      }
      .auth-header-container h1 {
          font-size: 20px;
      }
      .easySaff-auth {
          font-size: 32px !important;
      }
  }
</style>