function server() {
    return process.env.VUE_APP_API_URL_TN;
}

export const ApiConfigs = {
    base_url: server(),
    email_url: process.env.VUE_APP_API_URL_EMAIL,
    // captcha_url: process.env.VUE_APP_CAPTCHA_URL,
    /* -------------------------------- */
    // PUSHER
    email: {
        sendEmail: "/mail/send"
    }
};