import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import 'mdb-vue-ui-kit/css/mdb.min.css'
import 'vuetify/dist/vuetify.min.css'
import {createVuetify} from 'vuetify'

const vuetify = createVuetify();
createApp(App)
    .use(router)
    .use(vuetify)
    .mount('#app');
