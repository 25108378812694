import { authHeaderEmail, ApiConfigs } from '../_helpers';

export const emailService = {
    sendEmail,
    addContact
};

function sendEmail(payload) {
    payload.destination = process.env.VUE_APP_API_EMAIL_DESTINATION
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeaderEmail(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };
    return fetch(`${ApiConfigs.email_url+ApiConfigs.email.sendEmail}`, requestOptions).then(handleResponse);

}

function addContact(payload) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeaderEmail(), 'Content-Type': 'application/json' },
        body: payload
    };
    return fetch(`${ApiConfigs.email_url}`, requestOptions).then(handleResponse);

}


function handleResponse(response) {
    return response.text().then(text => {
        const data = JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}