<template>
  <div v-if="modelValue" class="form-container">
    <div class="form">
      <div v-if="!isSent" class="close-container">
        <div @click="close()" class="close">X</div>
      </div>
      <div v-if="!isSent && !isNotSent" class="form-div">
        <h3 style="margin-bottom: 20px">
          Contactez votre chargé clientéle
        </h3>
        <div id="myForm">
          <label style="width: 100%">
            <div>Raison social</div>
            <input class="form-control" v-model="socialReason" type="text" style="width: 100% !important;"
              placeholder="Raison social">
            <span class="error" v-if="errors.socialReason">Champ requis</span>
          </label>
          <div style="display: flex">
            <label style="width: 60%">
              <div>E-mail</div>
              <input v-model="email" class="form-control" type="email" style="width: 100% !important;"
                placeholder="exemple@domaine.com">
              <span class="error" v-if="errors.emailEmpty">Champ requis</span>
              <span class="error" v-else-if="errors.email && !errors.emailEmpty">Email doit être conforme</span>
            </label>
            <label style="width: 40%;margin-left: 10px">
              <div>Contact</div>
              <input v-model="fullPhoneNumber" @input="inputEvent" class="form-control" type="tel"
                style="width: 100% !important;" placeholder="+216 -- --- ---">
              <span class="error" v-if="errors.phoneNumber">Champ requis</span>
            </label>
          </div>
          <label style="width: 100%">
            <div>Message</div>
            <textarea v-model="message" minlength="20" class="form-control" type="email"
              style="width:100%;min-height: 150px !important;" placeholder="Tapez votre message ici ..."></textarea>
            <span class="error" v-if="errors.message">Champ doit comporter 20 caractères ou plus</span>
          </label>
          <div class="buttons">
            <button :disabled="sending" @click="send" type="submit" class="btn btn-info"
              style="margin-right: 40px;width: 120px;height: 40px">
              <span v-if="!sending">Envoyer</span>
              <div v-else class="spinner-border" style="color: white;height: 20px;width: 20px" role="status">
              </div>
            </button>
            <button :disabled="sending" @click="close()" type="button" class="btn btn-outline-info"
              style="width: 120px;height: 40px">Annuler</button>
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: center;width: 100%;height: 100%" v-else-if="isSent">
        <div style="margin: auto;display: flex;flex-direction: column">
          <div class="circle-container">
            <div class="circle">
              <div class="tick">
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
                  <path fill="none" stroke="#fff" stroke-width="10" stroke-linecap="round" d="M25,50 L42,67 L75,33" />
                </svg>
              </div>
            </div>
          </div>
          <span style="color: #28a8ca;font-size: 25px">Message envoyé avec succès</span>
        </div>
      </div>
      <div style="display: flex;justify-content: center;width: 100%;height: 100%" v-else-if="isNotSent">
        <div style="margin: auto;display: flex;flex-direction: column; text-align: center;">
          <div class="circle-container">
            <div class="circle" style="background: white;">

                  <img src="/warning.png" alt="" width="80">

            </div>
          </div>
          <p style="color: #b53a34;font-size: 25px">une erreur s'est produite</p>
          <p style="color: #b53a34;font-size: 25px">Veuillez réessayer ultérieurement</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { emailService } from "../_services";
export default {
  name: "formPopup",
  emits: ['close'],
  props: {
    modelValue: { required: true, type: Boolean },
  },
  computed: {
  },
  data() {
    return {
      message: "",
      phoneNumber: "",
      prefix: "+216",
      email: "",
      socialReason: "",
      sending: false,
      isSent: false,
      isNotSent: false,
      fullPhoneNumber: "",
      errors: {
        socialReason: false,
        email: false,
        emailEmpty: false,
        phoneNumber: false,
        message: false
      }
    }
  },
  methods: {
    close() {
      if (!this.sending)
        this.$emit('close');
    },
    validateFields() {
      this.errors.socialReason = this.socialReason.length == 0 ? true : false;
      this.errors.email = !this.validEmail(this.email) ? true : false;
      this.errors.phoneNumber = this.phoneNumber.length != 8 ? true : false;
      this.errors.message = this.message.length < 20 ? true : false;

      this.errors.emailEmpty = this.email.length == 0 ? true : false;

      let { socialReason, message, phoneNumber, email } = this.errors;
      return !(socialReason || message || phoneNumber || email);
    },
    validEmail: function (email) {
      /* eslint-disable */
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    inputEvent(event) {
      if (event.inputType === 'deleteContentBackward') {
        this.phoneNumber = this.phoneNumber.substring(0, this.phoneNumber.length - 1);
        this.setFullPhoneNumber();
      } else if (!isNaN(event.data)) {
        console.log("here");
        if (this.phoneNumber.length < 8) {
          this.phoneNumber += event.data;
          this.setFullPhoneNumber();
        } else {
          this.setFullPhoneNumber();
        }
      } else {
        this.setFullPhoneNumber();
      }
    },
    setFullPhoneNumber() {
      let num = this.phoneNumber;
      let fullNum = this.prefix;
      while (num.length) {
        if (fullNum.length === 4 || fullNum.length === 7 || fullNum.length === 11)
          fullNum += " ";
        fullNum += num[0];
        num = num.substring(1);
      }
      while (fullNum.length < 15) {
        if (fullNum.length === 4 || fullNum.length === 7 || fullNum.length === 11)
          fullNum += " ";
        fullNum += '-';
      }
      this.fullPhoneNumber = fullNum;
    },
    send() {
      let res = this.validateFields();
      if (!res) {
        return false;
      }

      this.sending = true;
      // 
      let payload = {
        raison_social: this.socialReason,
        mail: this.email,
        phone_number: this.phoneNumber,
        content: this.message
      }
      emailService.sendEmail(payload).then(() => {
        setTimeout(() => {
          this.isSent = true;
          setTimeout(() => {
            this.close();
          }, 2000)
        }, 1000)
      }).catch(() => {
        this.isNotSent = true;
        setTimeout(() => {
            this.close();
          }, 3000)
      }).finally(() => {
        this.sending = false;
      });
    }

  }
}
</script>
<style scoped>
.form-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  padding-top: 5%;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9999 !important;
}

.form {
  margin: auto;
  height: 90%;
  width: 800px;
  background: white;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.9);
}

.close-container {
  width: 100%;
  justify-content: right;
  display: flex;
}

.close {
  font-size: 30px;
  font-weight: bold;
  height: 45px;
  width: 50px;
  background: #28a8ca;
  color: white;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.close:hover {
  opacity: 0.9;
}

.form-div {
  padding: 10px 50px 10px 50px;
}

label {
  margin-bottom: 20px;
}

label div {
  font-size: 16px;
  padding-bottom: 1px;
}

label input {
  height: 40px;
}

.buttons {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.circle {
  width: 100px;
  height: 100px;
  background-color: #28a8ca;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 1s ease;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.tick {
  width: 100px;
  height: 100px;
}

svg path {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: drawTick 1s ease forwards;
}

@keyframes drawTick {
  to {
    stroke-dashoffset: 0;
  }
}

.error {
  position: absolute;
  padding: 2px 0;
  font-size: 11px;
  color: red;
}</style>