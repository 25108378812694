<template>
    <!-- ***** Header Area Start ***** -->
    <header style="height: 80px !important;" class="header-area header-sticky">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <nav style="display: flex !important;" class="main-nav">
                        <!-- ***** Logo Start ***** -->
                        <a :style="{'display': active ? 'none': ''}" href="" class="logo">
                            <img style="margin-top: -23px" width="70" src="../../assets/images/tt.png">
                            <img style="margin-top: -23px" width="70" src="../../assets/images/logo.png">
                        </a>
                        <!-- ***** Logo End ***** -->
                        <!-- ***** Menu Start ***** -->
                        <ul :class="{'custom-active': active}" style="width: 80% !important" class="nav">

                            <li style="width: 15%" @click="selected = 'presentation'" class="scroll-to-section"><a :class="{'selected': selected === 'presentation'}" href="#presentation" class="menu-item ">Présentation</a></li>
                            <li style="width: 15%" @click="selected = 'avatages'" class="scroll-to-section"><a :class="{'selected': selected === 'avatages'}" href="#avantages" class="menu-item">Avantages</a></li>
                            <li style="width: 15%" @click="selected = 'auth'" class="scroll-to-section"><a target="_blank" href="https://app.liberrex.com/login/tt" :class="{'selected': selected === 'auth'}" class="menu-item">S'authentifier</a></li>
                        </ul>
                        <a style="z-index: 9999" @click="expandMenu()" class='menu-trigger'>
                            <span>Menu</span>
                        </a>
                        <!-- ***** Menu End ***** -->
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>
<script>

    export default {
        name: 'navBar',
        data() {
            return {
                active:false,
                selected:'presentation',
            }
        },
        methods: {
        expandMenu(){
            const headerNav = document.querySelector('.header-area .nav');
            const menuTrigger = document.querySelector('.menu-trigger');
            if (menuTrigger.classList.contains('active')) {
                this.active = false;
                headerNav.style.display = 'none';
                menuTrigger.classList.remove('active');
            } else {
                this.active = true;
                headerNav.style.display = 'block';
                menuTrigger.classList.add('active');
            }
        }
        },
    }
</script>

<style scoped>
.custom-active{
    width: 100% !important;
    margin-left: 100px;
    background: white !important;padding: 20px !important;border-radius: 10px;
}
</style>