export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return {
            'Authorization': 'Bearer ' + user.token,
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Language': localStorage.getItem('Language')
        };
    } else {
        return {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Language': localStorage.getItem('Language')
        };
    }
}

export function authHeaderEmail() {
        return {
            'X-KEY': process.env.VUE_APP_API_EMAIL_KEY,
            'X-SECRET': process.env.VUE_APP_API_EMAIL_SECRET
        };
}