ù<template>

<div class="main-footer">

    <div style="width:47%;margin-left: 50%;position: absolute;padding-top: 30px">
    <img style="float: right" width="150" src="../../assets/images/liberrex.png"/>
    </div>
    <div style="width: 100%;position: absolute;z-index: 1;display: flex">
        <!--Background-->
    </div>
        <div style="padding-top: 10px;z-index: 99" class="container footer-item">
            <div class="row">
            <div class="col-auto">
            <img class="phone-img" style="margin-bottom: 10px;margin-top: 15px" height="60" src="../../assets/images/tt.png"/>
            </div>
            <div class="col-auto" style="text-align: left !important;padding-top: 20px">
                <a class="footer-link" target="_blank" href=""><span>Information légales</span></a> | <a class="footer-link" target="_blank" href=""><span>Plan du site</span></a><br>
                &copy; {{ new Date().getFullYear() }} Tunisie Telecom Tous droit réservés
        </div>
            </div>
</div>
</div>
</template>
<script>
    export default {
        name: 'FooterSection',
    }
</script>

<style scoped>
.footer-link {
    color: white;
    text-decoration: underline;
}
.footer-item{
    color:white;
    padding-left: 20px;
}
.main-footer {
    background: linear-gradient(111.3deg, #009AB9 9.6%, #283E78 93.6%);
    margin-top: 100px;
    padding-bottom: 10px;
    width: 100%;
    height: auto;
}

</style>