<template>

<div id="presentation" class="main-video">
    <h1 class="video-title">
        Avec Easy Saff gérez vos flux de clients intelligemment,<br>
        contrôlez et optimisez votre staff
    </h1><br>
<div class="video-container">
<div class="outer">
<video id="video" @click="playPauseVideo()" style="cursor: pointer" poster="../assets/images/video-thumbnail.png">
    <source src="../assets/videos/easysaff.mp4"/>
</video>
</div>
    </div>

</div>
</template>
<script>
    export default {
        name: 'VideoSection',

        methods: {
            playPauseVideo(){
                if(document.getElementById("video").paused) {
                    document.getElementById("video").play();
                } else {
                    document.getElementById("video").pause();
                }
            }
        },
    }
</script>

<style scoped>
.video-title {
    color: #0E2976;
    font-weight: 700;
}
.video-container {
    display: flex;
    width: 100%;
    justify-content: center;
}
.outer {
    justify-content: center;
    border-radius: 30px;
    width: 80%;
    overflow: hidden;
    position: relative;
}
video {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    opacity: 1;
}
.main-video{
    margin-top:100px;
    margin-bottom: 100px;
    width: 100%;
    text-align: center;
}


@media (max-width: 1320px) {
    .video-title {
        font-size: 47px;
    }
}
@media (max-width: 1320px) {
    .video-title {
        font-size: 37px;
    }
}
@media (max-width: 1050px) {
    .video-title {
        font-size: 27px;
    }
}
@media (max-width: 780px) {
    .video-title {
        font-size: 17px;
    }
}

</style>