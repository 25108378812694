<template>

    <nav-bar></nav-bar>
    <!-- ***** Welcome Area Start ***** -->
  <div style="margin-top: 100px" class="main-container">
    <header-section></header-section>
    <video-section></video-section>
    <AdvantagesSection></AdvantagesSection>
    <authentication-section></authentication-section>
    <contact-section></contact-section>
    <v-footer>
      <footer-section></footer-section>
    </v-footer>
  </div>
</template>
<script>
    import NavBar from "./navbar/NavBar";
    import HeaderSection from "@/components/HeaderSection.vue";
    import VideoSection from "@/components/VideoSection.vue";
    import AdvantagesSection from "@/components/AdvantagesSection.vue";
    import AuthenticationSection from "@/components/AuthenticationSection.vue";
    import ContactSection from "@/components/ContactSection.vue";
    import FooterSection from "@/components/footer/FooterSection.vue";
    export default {
        name: 'ClientPage',

        components: {
            FooterSection,
            ContactSection,
            AuthenticationSection,
            AdvantagesSection,
            VideoSection,
            HeaderSection,
            NavBar
        },
    }
</script>