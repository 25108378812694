<template>

<div id="avantages" class="main-advantages">
    <h1 class="advantages-title">
        Avantages
    </h1><br>
<div style="width: 100%;justify-content: center" class="container">
    <div style="width: 100%;justify-content: center;margin-left: 1px" class="row">
        <div class="col- advantage">
            <img src="../assets/images/calendar.png" width="80"/><br><br>
            <h4 class="advantage-title">Planification & Réservation</h4>
            <div class="advantage-text">
                Donnez à vos clients<br> l'opportunité de s'engager dans une file d'attente à distance ou<br> de réserver un
                rendez-vous en<br> ligne selon votre disponibilité
            </div>
        </div>
        <div class="col- advantage">
            <img src="../assets/images/calendar-watch.png" width="80"/><br><br>
            <h4 class="advantage-title">Optimisation & Performance</h4>
            <div class="advantage-text">
                    Contrôlez l'activité et la<br> performance de vos vis-à-vis<br> client
            </div>
        </div>
        <div class="col- advantage">
            <img src="../assets/images/calendar-check.png" width="80"/><br><br>
            <h4 class="advantage-title">Personnalisez vos relations </h4>
            <div class="advantage-text">
                Visualisez le niveau  de<br> satisfaction de vos clients<br>et personnalisez vos relations à<br>
                travers un CRM intégré
            </div>

        </div>
    </div>
</div>

</div>
</template>
<script>
    export default {
        name: 'AdvantagesSection',
    }
</script>

<style scoped>
.advantages-title {
    color: #0E2976;
    font-weight: 700;
}
.advantage-title {
    color:#70CEE0;
    font-weight: 800;
}
.advantage-text{
    color: #0E2976;
    width: 100%;
    font-weight: 600;
    text-align: left !important;
    justify-content: left !important;
    margin-left: 15px;
    margin-top: 15px;
}
.advantage {
    padding-top: 40px;
    justify-content: center;
    margin: 10px;
    width: 320px !important;
    height: 380px !important;
    border: 3px solid #70CEE0;
    border-radius: 40px;
    opacity: 1;
}
.main-advantages{
    margin-top:100px;
    margin-bottom: 100px;
    width: 100%;
    text-align: center;
}

</style>