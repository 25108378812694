ù<template>

<div id="contact" class="main-contact">
<div style="width: 100%;justify-content: center" class="container">
    <div style="width: 100%;justify-content: center;margin-left: 1px" class="row">
        <div class="container col-auto contact">
            <div style="justify-content: center !important;" class="row">

            <div class="col-auto">
            <img class="phone-img" style="margin-left: 40px;margin-bottom: 10px;margin-right: 40px !important;margin-top: 15px" height="60" src="../assets/images/phone-icon.png"/>
            </div>
                <div class="col-auto phone-text">
                <div>Besoin d'aide ? Appeler un assistant</div>
                <div style="font-size: 20px;font-weight: 500">1288*</div>
                <div style="font-size: 20px;font-weight: 500">ou +216 71 00 12 98</div>
            </div>
        </div>
        </div>
        <div class="col-auto contact">
          <a href="https://www.facebook.com/TunisieTelecom/?locale=fr_FR" target="_blank">
            <div style="display: flex;flex-direction: column;width: 150px;justify-content: center">
                <div style="width: 100%;display: flex;justify-content: center">
                <img width="50" src="../assets/images/messenger.png"/>
                </div>
                <span style="margin-top: 10px">Nous contacter sur messenger</span>
            </div>
          </a>
        </div>
        <div class="col-auto contact">
          <a href="https://www.tunisietelecom.tn/entreprise" target="_blank">
            <div style="display: flex;flex-direction: column;width: 150px;justify-content: center">
                <div style="width: 100%;display: flex;justify-content: center">
                    <img width="50" src="../assets/images/agreement.png"/>
                </div>
                <span style="margin-top: 10px">Les solutions Business Tawa</span>
            </div>
          </a>
        </div>
        <div class="col-auto contact">
          <a href="https://myspace.tunisietelecom.tn/Pages/TT-PresDeChezVous.aspx" target="_blank">
            <div style="display: flex;flex-direction: column;width: 150px;justify-content: center">
                <div style="width: 100%;display: flex;justify-content: center">
                    <img width="50" src="../assets/images/gps.png"/>
                </div>
                <span style="margin-top: 10px">Trouvez un espace TT</span>
            </div>
          </a>
        </div>
        <div class="col-auto contact" style="border: none !important;">
            <div style="display: grid;grid-template-columns: auto auto;margin-top: 7px">

              <a href="https://www.facebook.com/TunisieTelecom/?locale=fr_FR" target="_blank">
                <div style="border-right: 1px solid rgba(0, 0, 0, 0.2);border-bottom: 1px solid rgba(0, 0, 0, 0.2)" class="icon-tag">
                    <svg style="width: 20px" class="icon-tag" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
                </div>
              </a>

              <a href="https://www.linkedin.com/company/tunisie-t-l-com/?viewAsMember=true" target="_blank">
                <div style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)" class="icon-tag">
                    <svg style="width: 30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg>
                </div>
              </a>
              <a href="https://www.instagram.com/tunisietelecom/?hl=fr" target="_blank">
                <div style="border-right: 1px solid rgba(0, 0, 0, 0.2)" class="icon-tag">
                    <svg style="width: 28px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                </div>
              </a>
              <a href="https://twitter.com/_TunisieTelecom" target="_blank">
                <div class="icon-tag">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>                </div>
              </a>
            </div>
        </div>
    </div>
</div>

</div>
</template>
<script>
    export default {
        name: 'ContactSection',
    }
</script>

<style scoped>
.phone-text {
    text-align: left !important;margin-top: 5px;
}
.icon-tag {
    cursor: pointer;
    width: 50px;
    font-size: 30px;
    fill: lightgray;
    height: 50px;
}
.contact {
    justify-content: center;
    margin: 10px;
    border-right: 1px solid lightgray !important;
    opacity: 1;
    padding-bottom: 10px;
}

@media (max-width: 768px) {
    .phone-text {
        text-align: center !important
    }
    .contact {
        border-right: 1px solid transparent !important;
    }
}
.main-contact {
    margin-top: 100px;
    margin-bottom: 100px;
    width: 100%;
    text-align: center;
}
a {
  color: #4f4f4f;
}
</style>