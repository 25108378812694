<template>
  <div class="header-container">
      <div class="circle green-circle"></div>
      <div class="circle yellow-circle"></div>
      <div class="circle red-circle"></div>
      <div style="display: flex;width: 90%">
      <div class="dots">
        <div :class="{'selected-dot': showIndex === 1}" class="dot"></div>
        <div :class="{'selected-dot': showIndex === 2}" class="dot"></div>
        <div :class="{'selected-dot': showIndex === 3}" class="dot"></div>
        <div :class="{'selected-dot': showIndex === 4}" class="dot"></div>
        <div :class="{'selected-dot': showIndex === 5}" class="dot"></div>
      </div>

          <Transition>
          <!--First-->
          <div v-if="show === 1" style="width: 100%;padding-top: 100px">
              <h1>
                  <span class="easySaff"><span style="color:#58C9D1">EASY SAFF</span> BY TT</span> <br>UNE SOLUTION QUI VOUS<br> FERA GAGNER DU TEMPS
              </h1>
              <p class="header-text">
                  Contrôlez l'activité de votre service clientèle, digitalisez vos parcours client à travers une interface de gestion et automatisez vos réservations
              </p>
          </div>
          </Transition>

          <Transition>
          <!--Second-->
          <div v-if="show === 2" style="width: 100%;padding-top: 100px">
            <h1>
              <span class="easySaff"><span style="color:#58C9D1">EASY SAFF</span> BY TT</span> <br>UNE SOLUTION QUI VOUS<br> FERA GAGNER DU TEMPS
            </h1>
            <p class="header-text">
              Contrôlez l'activité de votre service clientèle, digitalisez vos parcours client à travers une interface de gestion et automatisez vos réservations
            </p>
          </div>
          </Transition>

          <Transition>
          <!--Third-->
          <div v-if="show === 3" style="width: 100%;padding-top: 100px">
            <h1>
              <span class="easySaff"><span style="color:#58C9D1">EASY SAFF</span> BY TT</span> <br>UNE SOLUTION QUI VOUS<br> FERA GAGNER DU TEMPS
            </h1>
            <p class="header-text">
              Contrôlez l'activité de votre service clientèle, digitalisez vos parcours client à travers une interface de gestion et automatisez vos réservations
            </p>
          </div>
          </Transition>

          <Transition>
          <!--Fourth-->
          <div v-if="show === 4" style="width: 100%;padding-top: 100px">
            <h1>
              <span class="easySaff"><span style="color:#58C9D1">EASY SAFF</span> BY TT</span> <br>UNE SOLUTION QUI VOUS<br> FERA GAGNER DU TEMPS
            </h1>
            <p class="header-text">
              Contrôlez l'activité de votre service clientèle, digitalisez vos parcours client à travers une interface de gestion et automatisez vos réservations
            </p>
          </div>
          </Transition>

          <Transition>
          <!--Fifth-->
          <div v-if="show === 5" style="width: 100%;padding-top: 100px">
            <h1>
              <span class="easySaff"><span style="color:#58C9D1">EASY SAFF</span> BY TT</span> <br>UNE SOLUTION QUI VOUS<br> FERA GAGNER DU TEMPS
            </h1>
            <p class="header-text">
              Contrôlez l'activité de votre service clientèle, digitalisez vos parcours client à travers une interface de gestion et automatisez vos réservations
            </p>
          </div>
          </Transition>

      </div>
      <img class="phone-image" height="488" width="412" src="../assets/images/phone.png">
  </div>
</template>
<script>
    export default {
        name: 'HeaderSection',
        data() {
            return {
                show:1,
                showIndex:1,
                changing:false,
            }
        },
        methods: {
          next() {
              if(this.changing)
                  return;
              this.changing = true;
              if(this.showIndex<5)
                  this.showIndex++;
              else
                  this.showIndex = 1;
              const x= this.show
              this.show =0;
              setTimeout(()=>{
                if(x<5)
                    this.show =x+1;
                else
                    this.show = 1;
                this.changing = false;
              },700);
          }
        },
        created() {
            setInterval(()=>{
                this.next();
            },4700)
        }
    }
</script>

<style scoped>

.circle {
    width: 40px;
    height: 40px;
    border-radius: 300px;
    position: absolute;
    filter: drop-shadow(2px 2px 0.15rem black)
}
.green-circle{
    margin-left: 91%;
    margin-top: 19%;
    background: linear-gradient(111.3deg, #279954 9.6%, #2DB36F 93.6%);
}

.yellow-circle{
    margin-left: 87%;
    margin-top: 26%;
    background: linear-gradient(111.3deg, #96812E 9.6%, #E0D752 93.6%);
}


.red-circle{
    margin-left: 79%;
    margin-top: 32%;
    background: linear-gradient(111.3deg, #E43824 9.6%, #F57825 93.6%);
}
.phone-image{
    float:right;
    margin-right: 100px;
    filter: drop-shadow(5px 5px 0.75rem black)
}
  .dots {
      padding-top: 150px;
      display: flex;
      flex-direction: column;
      width: 200px;
      padding-left: 100px;
  }
  .dot {
      margin-bottom: 20px;
      width: 10px;
      height: 10px;
      border-radius: 300px;
      background: #5091AF;
  }
  .selected-dot{
      cursor: default;
      height: 15px;
      margin-left: -2px;
      width: 15px;
      border: 2px solid white;
  }
  .header-container {
      display: flex;
      width: 100%;
      height: 470px;
      background: linear-gradient(111.3deg, #014D6F 9.6%, #45A6AD 93.6%);
  }
  .easySaff {
      word-spacing: 10px;
      font-size: 72px;
  }
  .header-container h1 {
      width: 100%;
      font-weight: 800;
      color: white;
      font-size: 50px;
  }
  .header-text{
      color: white;
      width: 80%;
  }
  @media (max-width: 1320px) {
      .header-container h1 {
          font-size: 40px;
      }
      .easySaff {
          font-size: 54px !important;
      }
  }
  @media (max-width: 1320px) {
      .header-container h1 {
        font-size: 40px;
      }
      .easySaff {
          font-size: 57px !important;
      }
  }
  @media (max-width: 1050px) {
      .header-text {
          display: none;
      }
      .header-container h1 {
          font-size: 30px;
      }
      .easySaff {
          font-size: 42px !important;
      }
  }
  @media (max-width: 780px) {
      .phone-image{
          display: none;
      }
      .dots {
          padding-left: 20px;
          margin-right: -50px;
      }
      .circle{
          display: none;
      }
      .header-text {
          display: none;
      }
      .dots{
          padding-top: 120px;
      }
      .header-container {
          height: 350px;
      }
      .header-container h1 {
          font-size: 20px;
      }
      .easySaff {
          font-size: 32px !important;
      }
  }
</style>